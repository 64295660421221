function isProduction() {
  return process.env.NODE_ENV === "production";
}

const isProd = isProduction();

const apiUrl = isProd
  ? "https://us-central1-ws-wedding.cloudfunctions.net/api"
  : "http://localhost:5000/ws-wedding/us-central1/api";

const version = 0.994;

const firebaseConfig = {
  apiKey: "AIzaSyClQNeKnX2-kjrAJWyK-NZ7NXNZTM2qRQI",
  authDomain: "ws-wedding.firebaseapp.com",
  projectId: "ws-wedding",
  storageBucket: "ws-wedding.appspot.com"
};

const googleAnalyticsConfig = {
  trackingId: isProd ? "UA-130268289-2" : "UA-130268289-1",
  debug: true || isProd ? false : true
};

const themeConfig = {
  topbar: "themedefault",
  sidebar: "themedefault",
  layout: "themedefault",
  theme: "themedefault"
};

const language = "en-US";

export {
  isProd,
  firebaseConfig,
  googleAnalyticsConfig,
  language,
  themeConfig,
  apiUrl,
  version
};
