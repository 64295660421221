import _ from "lodash";
import { generateBaseActionStrings } from "./actionUtils";
import analytics from "../../helpers/analytics";

const baseInitState = {
  itemsLoading: false,
  storeLoading: false,
  itemsFetchedTime: null,
  storeFetchedTime: null,
  currentlyLoadingItems: [],
  items: [],
  store: {},
  editItemModalActive: false,
  currentItem: null
};

function baseReducer(name, state, type, payload) {
  const {
    toggleAction,
    loadItemsAction,
    loadItemsSuccessAction,
    loadStoreAction,
    loadStoreSuccessAction,
    addAction,
    addSuccessAction,
    updateAction,
    updateSuccessAction,
    deleteAction,
    deleteSuccessAction,
    loadErrorAction
  } = generateBaseActionStrings(name);
  switch (type) {
    case toggleAction:
      if (!state.editItemModalActive) {
        analytics.trackModalView(`editing/${name}/item`);
      }
      return {
        ...state,
        editItemModalActive: !state.editItemModalActive,
        currentItem: payload.currentItem
      };
    case loadItemsAction:
      return {
        ...state,
        itemsLoading: true
      };
    case loadItemsSuccessAction:
      return {
        ...state,
        itemsLoading: false,
        itemsFetchedTime: payload.fetchedTime,
        items: payload.items,
        currentItem: { ...state.currentItem, isLoading: false }
      };
    case loadStoreAction:
      return {
        ...state,
        storeLoading: true
      };
    case loadStoreSuccessAction:
      return {
        ...state,
        storeLoading: false,
        storeFetchedTime: payload.fetchedTime,
        store: payload.store
      };
    case addAction:
      return {
        ...state,
        currentItem: { ...payload.currentItem, isLoading: true }
      };
    case addSuccessAction:
      return {
        ...state,
        items: _.flatten([state.items, payload.currentItem]),
        editItemModalActive: false,
        currentItem: { ...payload.currentItem }
      };
    case updateAction:
      return {
        ...state,
        currentItem: { ...payload.currentItem, isLoading: true },
        currentlyLoadingItems: _.flatten([
          state.currentlyLoadingItems,
          payload.currentItem.id
        ])
      };
    case updateSuccessAction:
      return {
        ...state,
        items: _.map(state.items, item => {
          return item.id === payload.currentItem.id
            ? { ...item, ...payload.currentItem }
            : { ...item };
        }),
        editItemModalActive: false,
        currentItem: { ...payload.currentItem },
        currentlyLoadingItems: _.without(
          state.currentlyLoadingItems,
          payload.currentItem.id
        )
      };
    case deleteAction:
      return {
        ...state,
        currentlyLoadingItems: _.flatten([
          state.currentlyLoadingItems,
          payload.currentItem.id
        ])
      };
    case deleteSuccessAction:
      return {
        ...state,
        items: _.filter(
          _.map(state.items, item => {
            return item.id === payload.currentItem.id ? null : { ...item };
          })
        ),
        currentlyLoadingItems: _.without(
          state.currentlyLoadingItems,
          payload.currentItem.id
        )
      };
    case loadErrorAction:
      return { ...baseInitState };
    default:
      return state;
  }
}

export { baseReducer, baseInitState };
