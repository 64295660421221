import * as _ from "lodash";

const fieldValidator = {
  REGEX: "regex",
  OPTIONS: "options",
  MIN_LENGTH: "min_length",
  MAX_LENGTH: "max_length",

  isValueValid: (valueToCheck, rules) => {
    const isValid = _.map(rules, rule => {
      const { key, value } = rule;
      if (key === fieldValidator.REGEX) {
        const regex = new RegExp(value);
        return regex.test(valueToCheck);
      }
      if (key === fieldValidator.OPTIONS) {
        return _.includes(value, valueToCheck);
      }
      if (key === fieldValidator.MIN_LENGTH) {
        return valueToCheck.length >= value;
      }
      if (key === fieldValidator.MAX_LENGTH) {
        return valueToCheck.length <= value;
      }
      return false;
    });
    return { isValid: _.every(isValid) };
  }
};

export default fieldValidator;
