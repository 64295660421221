import { store } from "./store";
import authActions from "./auth/actions";
import firebaseHelper from "../helpers/firebase";

export default () => {
  return firebaseHelper.getCurrentUser().then(() => {
    // Make sure we wait until the user is initialized before rendering the app.
    store.dispatch(authActions.checkAuthorization());
  });
};
