import MomentUtils from "./momentUtils";

const moment = MomentUtils.getMoment();
const DEFAULT_DATABASE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const DEFAULT_LOCALIZED_FORMAT = "llll";
const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
const WEDDING_DATE = "2019-03-22";

function turnStringDateToMoment(date, dateFormat = DEFAULT_DATABASE_FORMAT) {
  return moment(date, dateFormat);
}

function turnMomentDateIntoString(
  momentDate,
  dateFormat = DEFAULT_DATABASE_FORMAT
) {
  return momentDate.format(dateFormat);
}

function localizeMomentDate(momentDate, dateFormat = DEFAULT_LOCALIZED_FORMAT) {
  return momentDate.format(dateFormat);
}

function getWeddingDateInMoment() {
  return moment(WEDDING_DATE, DEFAULT_DATE_FORMAT);
}

export default {
  DEFAULT_DATABASE_FORMAT,
  DEFAULT_LOCALIZED_FORMAT,
  DEFAULT_DATE_FORMAT,
  WEDDING_DATE,
  turnStringDateToMoment,
  turnMomentDateIntoString,
  localizeMomentDate,
  getWeddingDateInMoment
};
