import _ from "lodash";
import { all, takeEvery, call, put } from "redux-saga/effects";
import notification from "../../components/notification";
import firebaseHelper from "../../helpers/firebase";
import { debugLogging } from "../../helpers/logging";
import { generateBaseActionStrings } from "../common/actionUtils";
import {
  baseLoadItems,
  baseLoadStore,
  baseAdd,
  baseUpdate,
  baseDelete,
  getData,
  getStore
} from "../common/sagaUtils";
import { getApiName } from "../common/nameMapping";
import actions from "./actions";

const name = "feedback";

const {
  loadItemsAction,
  loadStoreAction,
  addAction,
  updateAction,
  deleteAction,
  loadErrorAction
} = generateBaseActionStrings(name);

function* loadHostsContacts() {
  const { hostsContacts, hostsContactFetchedTime } = yield call(getStore, name);
  const apiName = getApiName(name);
  try {
    const token = yield call(firebaseHelper.getIdToken);
    const apiCall = `/${apiName}/hosts-contacts`;
    const { data, fetchedTime } = yield call(
      getData,
      apiCall,
      token,
      hostsContacts,
      hostsContactFetchedTime
    );
    debugLogging(`${name} Fetched@${fetchedTime}, Hosts-Contacts: `, data);
    yield put({
      type: actions.LOAD_HOSTS_CONTACTS_SUCCESS,
      payload: { hostsContacts: data, fetchedTime }
    });
  } catch (error) {
    notification("error", error.message);
    yield put({
      type: loadErrorAction
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(loadItemsAction, _.partial(baseLoadItems, name)),
    takeEvery(loadStoreAction, _.partial(baseLoadStore, name)),
    takeEvery(addAction, _.partial(baseAdd, name)),
    takeEvery(updateAction, _.partial(baseUpdate, name)),
    takeEvery(deleteAction, _.partial(baseDelete, name)),
    takeEvery(actions.LOAD_HOSTS_CONTACTS, loadHostsContacts)
  ]);
}
