import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import appSagas from "./app/saga";
import inviteSagas from "./invite/saga";
import inviteeSagas from "./invitee/saga";
import feedbackSagas from "./feedback/saga";
import agendaSagas from "./agenda/saga";
import appVisitSagas from "./appVisit/saga";
import travelSagas from "./travel/saga";
import faqSagas from "./faq/saga";
import poiSagas from "./poi/saga";
import photoSagas from "./photo/saga";
import storySagas from "./story/saga";
import registrySagas from "./registry/saga";
import registryContributingOptionsSagas from "./registryContributingOptions/saga";
import staticContentSagas from "./staticContent/saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    appSagas(),
    inviteSagas(),
    inviteeSagas(),
    feedbackSagas(),
    agendaSagas(),
    appVisitSagas(),
    travelSagas(),
    faqSagas(),
    poiSagas(),
    photoSagas(),
    storySagas(),
    registrySagas(),
    registryContributingOptionsSagas(),
    staticContentSagas()
  ]);
}
