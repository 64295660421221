const inviteeActions = {
  TOGGLE_EDIT_INVITEE: "TOGGLE_EDIT_INVITEE",

  LOAD_INVITEES: "LOAD_INVITEES",
  LOAD_INVITEES_SUCCESS: "LOAD_INVITEES_SUCCESS",
  LOAD_INVITEES_ERROR: "LOAD_INVITEES_ERROR",

  ADD_INVITEE: "ADD_INVITEE",
  ADD_INVITEE_SUCCESS: "ADD_INVITEE_SUCCESS",

  UPDATE_INVITEE: "UPDATE_INVITEE",
  UPDATE_INVITEE_SUCCESS: "UPDATE_INVITEE_SUCCESS",

  DELETE_INVITEE: "DELETE_INVITEE",
  DELETE_INVITEE_SUCCESS: "DELETE_INVITEE_SUCCESS",

  toggleEditInvitee: (invitee = null) => ({
    type: inviteeActions.TOGGLE_EDIT_INVITEE,
    payload: { invitee }
  }),

  loadInvitees: () => {
    return { type: inviteeActions.LOAD_INVITEES };
  },

  addInvitee: invitee => ({
    type: inviteeActions.ADD_INVITEE,
    payload: { invitee }
  }),

  updateInvitee: invitee => ({
    type: inviteeActions.UPDATE_INVITEE,
    payload: { invitee }
  }),

  deleteInvitee: invitee => ({
    type: inviteeActions.DELETE_INVITEE,
    payload: { invitee }
  })
};
export default inviteeActions;
