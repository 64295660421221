import React from "react";
import { injectIntl, intlShape } from "react-intl";
import Editor from "../uielements/editor";

const LocalizedEditor = props => {
  const { intl, placeHolder } = props;
  const editorProps = { ...props };
  if (placeHolder) {
    editorProps.placeHolder = intl.formatMessage({ id: placeHolder });
  }
  return <Editor {...editorProps} />;
};

LocalizedEditor.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(LocalizedEditor);
