import _ from "lodash";
import { store } from "./store";
import feedbackActions from "./feedback/actions";
import inviteActions from "./invite/actions";
import staticContentActions from "./staticContent/actions";

function initAllStores() {
  store.dispatch(feedbackActions.loadFeedbackStore());
  store.dispatch(feedbackActions.loadHostsContacts());
  store.dispatch(inviteActions.loadMyInvite());
  store.dispatch(staticContentActions.loadStaticContentItems());
}

function storesLoading(stores) {
  return _.some(
    _.map(stores, aStore => {
      return aStore.storeLoading;
    })
  );
}

export { initAllStores, storesLoading };
