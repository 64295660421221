import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import { message } from "antd";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css";
import QuillEditor from "./styles/editor.style";
import firebaseHelper from "../../helpers/firebase";
import LocalizedMessage from "../i18n/localizedMessage";

Quill.register(Quill.import("attributors/style/align"), true);

export default class Editor extends Component {
  constructor(props) {
    super(props);
    this.uploadPicture = this.uploadPicture.bind(this);
    this.insertToEditor = this.insertToEditor.bind(this);
    this.quillModules = {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, false] }],
          [{ font: [] }],
          [{ size: [] }],
          [{ color: [] }, { background: [] }, { align: [] }],
          ["bold", "italic", "underline", "strike"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          ["link", "image", "video"],
          ["clean"]
        ],
        handlers: { image: this.uploadPicture }
      }
    };
  }

  uploadImage = async e => {
    const file = e.target.files[0];
    // file type is only image.
    if (/^image\//.test(file.type)) {
      message.info(<LocalizedMessage messageId="imageUploader.uploading" />);
      const url = await firebaseHelper.uploadFile(file);
      message.success(<LocalizedMessage messageId="imageUploader.uploaded" />);
      this.insertToEditor(url);
    } else {
      message.error(<LocalizedMessage messageId="imageUploader.onlyImage" />);
    }
  };

  insertToEditor(url) {
    var range = this.editor.getEditor().getSelection();
    this.editor.getEditor().insertEmbed(range.index, "image", url, "user");
  }

  uploadPicture() {
    this.uploader.click();
  }

  render() {
    const options = {
      theme: "snow",
      formats: Editor.formats,
      placeholder: this.props.placeHolder,
      defaultValue: this.props.initialValue,
      onChange: this.props.onChange,
      modules: this.quillModules,
      readOnly: this.props.disabled
    };
    return (
      <QuillEditor>
        <ReactQuill ref={ref => (this.editor = ref)} {...options} />
        <input
          ref={input => (this.uploader = input)}
          type="file"
          onChange={this.uploadImage}
          style={{ display: "none" }}
        />
      </QuillEditor>
    );
  }
}
