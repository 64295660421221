import React, { Component } from "react";
import { connect } from "react-redux";
import Popover from "../../components/uielements/popover";
import IntlMessages from "../../components/utility/intlMessages";
import authAction from "../../redux/auth/actions";
import TopbarDropdownWrapper from "./topbarDropdown.style";
import feedbackActions from "../../redux/feedback/actions";

const { logout } = authAction;

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  anyModalActive() {
    return this.props.feedback.editItemModalActive;
  }

  openEditFeedbackModal = (feedback = null) => {
    if (this.anyModalActive()) {
      return;
    }
    this.setState({ visible: false });
    this.props.toggleEditFeedbackItem(feedback);
  };

  render() {
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <button
          onClick={this.openEditFeedbackModal.bind(this, null)}
          className="isoDropdownLink"
        >
          <IntlMessages id="topbar.contact" />
        </button>
        <button className="isoDropdownLink" onClick={this.props.logout}>
          <IntlMessages id="topbar.logout" />
        </button>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <i className="ion-android-settings" />
      </Popover>
    );
  }
}
export default connect(
  state => ({
    feedback: state.Feedback
  }),
  { logout, ...feedbackActions }
)(TopbarUser);
