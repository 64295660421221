import React, { Component } from "react";
import { connect } from "react-redux";
import { LocaleProvider } from "antd";
import { IntlProvider } from "react-intl";
import IntlMessages from "../utility/intlMessages";
import { AppLocale } from "../../dashApp";
import { getCurrentLanguage } from "../../containers/LanguageSwitcher/config";
import { store } from "../../redux/store";

class LocalizedMessage extends Component {
  render() {
    const { language, messageId } = this.props;
    const currentAppLocale =
      AppLocale[getCurrentLanguage(language || "english").locale];
    return (
      <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <IntlMessages id={messageId} />
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

const WrappedMessage = connect(
  state => ({
    language: state.Invite.myInvite.language
  }),
  {}
)(LocalizedMessage);

export default function(props) {
  return <WrappedMessage store={store} {...props} />;
}
