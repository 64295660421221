import { baseReducer, baseInitState } from "../common/reducerUtils";
import { generateBaseActionStrings } from "../common/actionUtils";
import actions from "./actions";
const name = "invite";

const initState = {
  ...baseInitState,
  myInvite: { rsvpStep: 0 },
  myInviteLoading: false,
  myInviteFetchedTime: null,
  languageChanging: false
};

const { updateSuccessAction } = generateBaseActionStrings(name);

export default function reducer(state = initState, { type, payload }) {
  const newState = baseReducer(name, state, type, payload);
  if (type === actions.LOAD_MY_INVITE) {
    newState.myInviteLoading = true;
  }
  if (type === actions.LOAD_MY_INVITE_SUCCESS) {
    newState.myInviteLoading = false;
    newState.myInvite = payload.myInvite;
    newState.myInviteFetchedTime = payload.fetchedTime;
  }
  if (type === actions.CHANGE_LANGUAGE) {
    newState.languageChanging = true;
  }
  if (type === updateSuccessAction) {
    if (payload.currentItem.id === state.myInvite.id) {
      newState.myInvite = { ...state.myInvite, ...payload.currentItem };
    }
  }
  return newState;
}
