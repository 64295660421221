const OptionsForAll = [
  {
    key: "welcome",
    label: "sidebar.welcome",
    leftIcon: "ion-bowtie",
    order: 0
  },
  {
    key: "our-story",
    label: "sidebar.ourStory",
    leftIcon: "ion-android-favorite",
    order: 1
  },
  {
    key: "photos",
    label: "sidebar.photos",
    leftIcon: "ion-ios-photos-outline",
    order: 1
  },
  {
    key: "agenda",
    label: "sidebar.agenda",
    leftIcon: "ion-ios-calendar-outline",
    order: 4
  }
];
const AttendingOptions = [];
const InviteeOptions = [
  {
    key: "my-invite",
    label: "sidebar.myInvite",
    leftIcon: "ion-android-drafts",
    order: 3
  }
];

const HostOptions = [
  {
    key: "faqs",
    label: "sidebar.faqs",
    leftIcon: "ion-help-circled",
    order: 7
  },
  {
    key: "things-to-do",
    label: "sidebar.poi",
    leftIcon: "ion-android-bicycle",
    order: 8
  },

  {
    key: "travel",
    label: "sidebar.travel",
    leftIcon: "ion-android-plane",
    order: 5
  },
  {
    key: "registry",
    label: "sidebar.registry",
    leftIcon: "ion-bag",
    order: 6
  },
  {
    key: "registry-contributing-options",
    label: "sidebar.contributingMethods",
    leftIcon: "ion-cash",
    order: 98
  },
  {
    key: "invites",
    label: "sidebar.allInvites",
    leftIcon: "ion-filing",
    order: 99
  },
  {
    key: "static-contents",
    label: "sidebar.staticContents",
    leftIcon: "ion-ios-paper",
    order: 100
  },
  {
    key: "feedback",
    label: "sidebar.feedback",
    leftIcon: "ion-android-textsms",
    order: 101
  },
  {
    key: "app-visit",
    label: "sidebar.appVisit",
    leftIcon: "ion-clipboard",
    order: 102
  }
];
export { OptionsForAll, HostOptions, InviteeOptions, AttendingOptions };
