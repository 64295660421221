import React from "react";
import styled from "styled-components";
import { palette } from "styled-theme";
import Cards from "../../components/uielements/card";

const smallScreen = "(max-width: 650px)";

const LayoutContent = styled.div`
  margin-top: 15px;
  width: 100%;
  padding: 20px;
  height: 100%;
  @media only screen and ${smallScreen} {
    padding: 0px;
    margin-top: 5px;
  }
`;

const Details = styled.div`
  .ql-editor {
    overflow-y: hidden;
  }
`;

const DetailsInner = styled.div`
  display: block;
  height: 100%;
  img {
    max-width: 100%;
  }
`;

const PictureDisplay = styled.div`
  max-height: 100px;
  @media only screen and ${smallScreen} {
    max-height: 70px;
  }
  img {
    max-height: 100px;
    @media only screen and ${smallScreen} {
      max-height: 70px;
    }
  }
`;

const Subtitle = styled.div`
  display: block;
  color: ${palette("text", 3)};
  text-align: center;
`;

const CardComp = props => <Cards {...props} />;

const Card = styled(CardComp)`
  .ant-card-head {
    .ant-card-head-title {
      display: block;
      font-weight: 700;
      font-size: 24px;
      color: ${palette("text", 0)};
      text-align: center;
      @media only screen and ${smallScreen} {
        font-size: 18px;
      }
    }
  }

  .ant-card-extra {
    a {
      color: ${palette("primary", 0)};
      text-decoration: none;

      &:focus {
        text-decoration: none;
      }
    }
  }

  .ant-card-body {
    @media only screen and ${smallScreen} {
      padding: 10px;
    }
    p {
      font-size: 13px;
      color: ${palette("text", 3)};
      line-height: 1.5;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.ant-card-bordered {
    margin: 40px 0px;
    border: 1px solid ${palette("border", 0)};
    @media only screen and ${smallScreen} {
      margin: 8px 0px;
    }
    .ant-card-head {
      border-bottom: 1px solid ${palette("border", 0)};
    }

    &:hover {
      border: 1px solid ${palette("border", 0)} !important;
    }
  }

  &.ant-card-loading {
    .ant-card-body {
      p {
        margin-bottom: 0;
      }
    }
  }

  .custom-card {
    padding: 10px 16px;
    h3 {
      color: ${palette("text", 1)};
      font-weight: 500;
    }
    p {
      color: ${palette("grayscale", 0)};
    }
  }

  .custom-image img {
    display: block;
  }

  .ql-editor {
    padding: 1px;
  }
`;

const CardContainerCard = styled(CardComp)`
  .ant-card-head {
    .ant-card-head-title {
      color: ${palette("text", 0)};
    }
  }

  .ant-card-extra {
    a {
      color: ${palette("primary", 0)};
      text-decoration: none;

      &:focus {
        text-decoration: none;
      }
    }
  }

  .ant-card-body {
    @media only screen and ${smallScreen} {
      padding: 10px;
    }
    p {
      font-size: 13px;
      color: ${palette("text", 3)};
      line-height: 1.5;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.ant-card-bordered {
    margin: 10px 0px;
    border: 1px solid ${palette("border", 0)};

    .ant-card-head {
      border-bottom: 1px solid ${palette("border", 0)};
    }

    &:hover {
      border: 1px solid ${palette("border", 0)} !important;
    }
  }

  &.ant-card-loading {
    .ant-card-body {
      p {
        margin-bottom: 0;
      }
    }
  }

  .custom-card {
    padding: 10px 16px;
    h3 {
      color: ${palette("text", 1)};
      font-weight: 500;
    }
    p {
      color: ${palette("grayscale", 0)};
    }
  }

  .custom-image img {
    display: block;
  }
  .ql-editor {
    padding: 1px;
  }
`;

const MessageWrapper = styled.div`
  padding: 0 20px;
  width: 100%;
  align-items: center;
  @media only screen and (min-width: 780px) {
    padding: 0 100px;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
  align-items: center;
  @media only screen and (min-width: 780px) {
    padding: 0 100px;
  }
  .filter-item {
    margin-bottom: 20px;
  }
`;

const CenterItems = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  img {
    height: 30px;
  }
`;

export {
  LayoutContent,
  Details,
  DetailsInner,
  Card,
  PictureDisplay,
  Subtitle,
  CardContainerCard,
  MessageWrapper,
  FilterWrapper,
  CenterItems
};
