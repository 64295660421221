import { language } from "../../settings";

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: "en-US",
      locale: "english",
      text: "English"
    },
    {
      languageId: "zh-CN",
      locale: "simplifiedChinese",
      text: "简体"
    },
    {
      languageId: "zh-TW",
      locale: "tranditionalChinese",
      text: "繁體"
    }
  ]
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach(language => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}
export default config;
