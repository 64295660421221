import React from "react";
import ReactDOM from "react-dom";
import DashApp from "./dashApp";
import * as serviceWorker from "./serviceWorker";
import "antd/dist/antd.css";
import Boot from "./redux/boot";

Boot()
  .then(() => {
    ReactDOM.render(<DashApp />, document.getElementById("root"));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister();
  })
  .catch(error => console.error(error));
