import antdZhTW from "antd/lib/locale-provider/zh_TW";
import appLocaleData from "react-intl/locale-data/zh";
import zhTWMessages from "../locales/zh-TW.json";

const ZhTWLan = {
  messages: {
    ...zhTWMessages
  },
  antd: antdZhTW,
  locale: "zh-TW",
  data: appLocaleData
};
export default ZhTWLan;
