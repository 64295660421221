import { all, takeEvery, call } from "redux-saga/effects";
import _ from "lodash";
import actions from "./actions";
import notification from "../../components/notification";
import customComponents from "../../components/custom/customComponents";
import { version } from "../../settings";
import superFetch from "../../helpers/superFetch";
import analytics from "../../helpers/analytics";

const { updateNotification } = customComponents;

let checkUpdateStarted = false;

analytics.trackCurrentAppVersion(version);

async function _checkUpdate(notificationDuration) {
  const { minimumVersion } = await superFetch.get("/minimumVersion");
  console.info("Current App Version: ", version);
  console.info("Minimum Version Required", minimumVersion);
  const updateNeeded = version < minimumVersion;
  if (updateNeeded) {
    updateNotification(notificationDuration);
    analytics.trackUpdateNeeded(version, minimumVersion);
  }
}

function* checkUpdate(payload) {
  try {
    if (checkUpdateStarted) return;
    checkUpdateStarted = true;
    const { frequencyMills } = payload;
    let notificationDuration = frequencyMills / 1000 - 5;
    if (notificationDuration <= 0) {
      notificationDuration = 3;
    }
    const checkUpdateFunc = _.partial(_checkUpdate, notificationDuration);
    yield call(checkUpdateFunc);
    setInterval(checkUpdateFunc, frequencyMills);
  } catch (error) {
    notification("error", error.message);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CHECK_UPDATE, checkUpdate)]);
}
