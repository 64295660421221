import { all, takeEvery, put, call } from "redux-saga/effects";
import * as _ from "lodash";
import actions from "./actions";
import notification from "../../components/notification";
import firebaseHelper from "../../helpers/firebase";
import { debugLogging } from "../../helpers/logging";
import superFetch from "../../helpers/superFetch";

function* loadInvitees() {
  try {
    const token = yield call(firebaseHelper.getIdToken);
    const [invitees, store] = yield all([
      call(superFetch.get, "/invitees", {
        token
      }),
      call(superFetch.get, "/store/invitee", {
        token
      })
    ]);
    debugLogging("All Invitees", invitees);
    debugLogging("Invitee Store", store);
    yield put({
      type: actions.LOAD_INVITEES_SUCCESS,
      payload: { invitees, store }
    });
  } catch (error) {
    notification("error", error.message);
    yield put({
      type: actions.LOAD_INVITEES_ERROR
    });
  }
}

function* addInvitee(payload) {
  let { invitee } = payload.payload;
  invitee = _.pickBy(invitee);
  try {
    const token = yield call(firebaseHelper.getIdToken);
    const { inviteeId } = yield call(superFetch.post, "/invitee", {
      body: { invitee },
      token
    });
    invitee.id = inviteeId;
    yield put({
      type: actions.ADD_INVITEE_SUCCESS,
      payload: { invitee }
    });
  } catch (error) {
    notification("error", error.message);
    yield put(actions.loadInvitees());
  }
}

function* updateInvitee(payload) {
  let { invitee } = payload.payload;
  invitee = _.pickBy(invitee);
  try {
    const token = yield call(firebaseHelper.getIdToken);
    yield call(superFetch.post, `/invitee/${invitee.id}`, {
      body: { invitee },
      token
    });
    yield put({
      type: actions.UPDATE_INVITEE_SUCCESS,
      payload: { invitee }
    });
  } catch (error) {
    notification("error", error.message);
    yield put(actions.loadInvitees());
  }
}

function* deleteInvitee(payload) {
  const { invitee } = payload.payload;
  try {
    const token = yield call(firebaseHelper.getIdToken);
    yield call(superFetch.delete, `/invitee/${invitee.id}`, {
      token
    });
    yield put({
      type: actions.DELETE_INVITEE_SUCCESS,
      payload: { invitee }
    });
  } catch (error) {
    notification("error", error.message);
    yield put(actions.loadInvitees());
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_INVITEES, loadInvitees),
    takeEvery(actions.ADD_INVITEE, addInvitee),
    takeEvery(actions.UPDATE_INVITEE, updateInvitee),
    takeEvery(actions.DELETE_INVITEE, deleteInvitee)
  ]);
}
