import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";

const routes = [
  {
    path: "",
    component: asyncComponent(() => import("../Page/404"))
  },
  {
    path: "welcome",
    component: asyncComponent(() => import("../dashboard"))
  },
  {
    path: "invites",
    component: asyncComponent(() => import("../invites"))
  },
  {
    path: "my-invite",
    component: asyncComponent(() => import("../invitees"))
  },
  {
    path: "feedback",
    component: asyncComponent(() => import("../feedback"))
  },
  {
    path: "agenda",
    component: asyncComponent(() => import("../agenda"))
  },
  {
    path: "app-visit",
    component: asyncComponent(() => import("../appVisit"))
  },
  {
    path: "travel",
    component: asyncComponent(() => import("../travel"))
  },
  {
    path: "faqs",
    component: asyncComponent(() => import("../faq"))
  },
  {
    path: "things-to-do",
    component: asyncComponent(() => import("../poi"))
  },
  {
    path: "our-story",
    component: asyncComponent(() => import("../story"))
  },
  {
    path: "registry",
    component: asyncComponent(() => import("../registry"))
  },
  {
    path: "registry-contributing-options",
    component: asyncComponent(() => import("../registryContributingOptions"))
  },
  {
    path: "static-contents",
    component: asyncComponent(() => import("../staticContent"))
  },
  {
    path: "photos",
    component: asyncComponent(() => import("../photo"))
  },
  {
    path: "*",
    component: asyncComponent(() => import("../Page/404"))
  }
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        <Switch>
          {routes.map(singleRoute => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <Route
                exact={exact === false ? false : true}
                key={singleRoute.path}
                path={`${url}/${singleRoute.path}`}
                {...otherProps}
              />
            );
          })}
        </Switch>
      </div>
    );
  }
}

export default AppRouter;
