import { baseReducer, baseInitState } from "../common/reducerUtils";
import { generateBaseActionStrings } from "../common/actionUtils";
import actions from "./actions";
const name = "feedback";
const { addSuccessAction } = generateBaseActionStrings(name);

const initState = {
  ...baseInitState,
  hostsContacts: null,
  hostsContactsLoading: false,
  hostsContactsFetchedTime: null
};
export default function reducer(state = initState, { type, payload }) {
  const newState = baseReducer("feedback", state, type, payload);
  if (type === addSuccessAction) {
    newState.editItemModalActive = true;
    newState.currentItem.success = true;
  }
  if (type === actions.LOAD_HOSTS_CONTACTS) {
    newState.hostsContactsLoading = true;
  }
  if (type === actions.LOAD_HOSTS_CONTACTS_SUCCESS) {
    newState.hostsContactsLoading = false;
    newState.hostsContacts = payload.hostsContacts;
    newState.hostsContactsFetchedTime = payload.fetchedTime;
  }
  return newState;
}
