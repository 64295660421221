import { generateBaseActions } from "../common/actionUtils";

const actions = {
  ...generateBaseActions("feedback"),
  LOAD_HOSTS_CONTACTS: "LOAD_HOSTS_CONTACTS",
  LOAD_HOSTS_CONTACTS_SUCCESS: "LOAD_HOSTS_CONTACTS_SUCCESS",
  loadHostsContacts: () => ({
    type: actions.LOAD_HOSTS_CONTACTS
  })
};

export default actions;
