import moment from "moment";
import "moment/locale/zh-cn";
import "moment/locale/zh-tw";

class MomentUtils {
  constructor() {
    moment.locale("en-us");
  }

  getMoment() {
    return moment;
  }

  updateMomentLocale(locale) {
    moment.locale(locale);
  }
}

export default new MomentUtils();
