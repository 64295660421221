import React from "react";
import { Link } from "react-router-dom";
import logo from "../../image/logo.png";
import IntlMessages from "../../components/utility/intlMessages";

export default ({ collapsed, onClick }) => {
  return (
    <div className="isoLogoWrapper" onClick={onClick}>
      {collapsed ? (
        <div>
          <h3>
            <Link to="/dashboard/welcome">
              <img
                alt="wai and shan logo"
                src={logo}
                style={{ width: 50, height: 50 }}
              />
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link to="/dashboard/welcome">
            <IntlMessages id="siteConfig.name" />
          </Link>
        </h3>
      )}
    </div>
  );
};
