import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import actions from "./actions";
import firebaseHelper from "../../helpers/firebase";
import analytics from "../../helpers/analytics";
import notification from "../../components/notification";

export function* loginRequest() {
  yield takeEvery("LOGIN_REQUEST", function*({ payload }) {
    const { inviteCode, rememberMe } = payload;
    try {
      const result = yield call(firebaseHelper.login, {
        inviteCode,
        rememberMe
      });
      if (result.user) {
        analytics.trackLogin(result.user.uid);
        yield put({
          type: actions.LOGIN_SUCCESS,
          payload: result,
          token: "success"
        });
      }
    } catch (error) {
      notification("error", error.message);
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*({ token }) {});
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    yield call(firebaseHelper.logout);
    window.location.reload();
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const user = yield call(firebaseHelper.getCurrentUser);
    if (user) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: "success"
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout)
  ]);
}
