import _ from "lodash";

const mapping = {
  registryContributingOptions: {
    apiName: "registry/contributing/options",
    upperFirst: "RegistryContributingOptions",
    upper: "REGISTRY_CONTRIBUTING_OPTIONS",
    lower: "registry_contributing_options"
  },
  staticContent: {
    apiName: "static-content",
    upperFirst: "StaticContent",
    upper: "STATIC_CONTENT",
    lower: "static_content"
  },
  appVisit: {
    apiName: "app-visit",
    upperFirst: "AppVisit",
    upper: "APP_VISIT",
    lower: "app_visit"
  }
};

function getUpper(name) {
  const options = mapping[name];
  return (options && options.upper) || _.toUpper(name);
}

function getLower(name) {
  const options = mapping[name];
  return (options && options.lower) || _.toLower(name);
}

function getUpperFirst(name) {
  const options = mapping[name];
  return (options && options.upperFirst) || _.upperFirst(getLower(name));
}

function getApiName(name) {
  const options = mapping[name];
  return (options && options.apiName) || _.toLower(name);
}

export { getUpper, getLower, getApiName, getUpperFirst };
