import React from "react";
import { Route, Redirect, Switch, BrowserRouter } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";

import App from "./containers/App/App";
import asyncComponent from "./helpers/AsyncFunc";

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history}>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path={"/"}
            component={asyncComponent(() => import("./containers/Page/signin"))}
          />
          <Route
            exact
            path={"/signin"}
            component={asyncComponent(() => import("./containers/Page/signin"))}
          />
          <RestrictedRoute
            path="/dashboard"
            component={App}
            isLoggedIn={isLoggedIn}
          />
          <Route
            exact
            path={"*"}
            component={asyncComponent(() => import("./containers/Page/404"))}
          />
        </Switch>
      </BrowserRouter>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isLoggedIn: state.Auth.idToken !== null
}))(PublicRoutes);
