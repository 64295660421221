import { all, takeEvery } from "redux-saga/effects";
import { generateBaseActionStrings } from "../common/actionUtils";
import {
  baseLoadItems,
  baseLoadStore,
  baseAdd,
  baseUpdate,
  baseDelete
} from "../common/sagaUtils";
import _ from "lodash";

const name = "story";

const {
  loadItemsAction,
  loadStoreAction,
  addAction,
  updateAction,
  deleteAction
} = generateBaseActionStrings(name);

export default function* rootSaga() {
  yield all([
    takeEvery(loadItemsAction, _.partial(baseLoadItems, name)),
    takeEvery(loadStoreAction, _.partial(baseLoadStore, name)),
    takeEvery(addAction, _.partial(baseAdd, name)),
    takeEvery(updateAction, _.partial(baseUpdate, name)),
    takeEvery(deleteAction, _.partial(baseDelete, name))
  ]);
}
