import React from "react";
import { injectIntl, intlShape } from "react-intl";
import { Input } from "antd";

const LocalizedInput = props => {
  const { intl, placeholder } = props;
  const inputProps = { ...props };
  if (placeholder) {
    inputProps.placeholder = intl.formatMessage({ id: placeholder });
  }
  return <Input {...inputProps} />;
};

LocalizedInput.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(LocalizedInput);
