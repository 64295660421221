import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "../../components/feedback/modal";
import Button from "../../components/uielements/button";
import Spin from "../../components/feedback/spin.style";
import actions from "../../redux/languageSwitcher/actions";
import config, { getCurrentLanguage } from "./config";
import inviteActions from "../../redux/invite/actions";
import { LanguageSelector } from "./languageSwitcher.style";
import translateIcon from "../../image/translate.png";
import IntlMessages from "../../components/utility/intlMessages";

class LanguageSwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  render() {
    const {
      isActivated,
      switchActivation,
      changeLanguage,
      myInvite
    } = this.props;
    const { loading } = this.state;
    const language = getCurrentLanguage(myInvite.language || "en-US");
    return (
      <div className="isoButtonWrapper">
        <img
          alt="translateIcon"
          src={translateIcon}
          onClick={switchActivation}
        />
        <Modal
          title=<IntlMessages id="languageSwitcher.title" />
          visible={isActivated}
          onCancel={switchActivation}
          cancelText="Cancel"
          footer={[]}
        >
          <Spin spinning={loading}>
            <LanguageSelector>
              {config.options.map(option => {
                const { languageId, text } = option;
                const type =
                  languageId === language.languageId ? "primary" : "success";
                return (
                  <Button
                    type={type}
                    key={languageId}
                    onClick={() => {
                      this.setState({ loading: true });
                      changeLanguage({ myInvite, language: languageId });
                    }}
                  >
                    {text}
                  </Button>
                );
              })}
            </LanguageSelector>
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default connect(
  state => ({
    ...state.LanguageSwitcher,
    myInvite: state.Invite.myInvite
  }),
  { ...actions, ...inviteActions }
)(LanguageSwitcher);
