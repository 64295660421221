import ReactGA from "react-ga";
import { googleAnalyticsConfig } from "../settings";

const eventCategories = {
  APP_UPDATE: "APP_UPDATE",
  APP_VERSION: "APP_VERSION",
  INITAL_APP_LOAD: "INITAL_APP_LOAD_V2",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT"
};

ReactGA.initialize(googleAnalyticsConfig.trackingId, {
  debug: googleAnalyticsConfig.debug,
  titleCase: true
});

function trackException(description, fatal = false) {
  ReactGA.exception({
    description,
    fatal
  });
}

function trackEvent(category, action, nonInteraction, label = "") {
  ReactGA.event({
    category,
    action,
    label,
    nonInteraction
  });
}

function trackTiming(category, variable, value, label = "") {
  ReactGA.timing({
    category,
    variable,
    value,
    label
  });
}

class Analytics {
  setUser(userId) {
    ReactGA.set({ userId });
  }
  trackModalView(name) {
    ReactGA.modalview(name);
  }
  trackPageView() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  trackAPICallErrors(method, url, body, error, fatal) {
    const description =
      `Api call error: ${method}, ${url}:` +
      JSON.stringify(body) +
      "\n" +
      JSON.stringify(error);
    trackException(description, fatal);
  }
  trackCurrentAppVersion(currentVersion) {
    trackEvent(
      eventCategories.APP_VERSION,
      `Running App Version: ${currentVersion}`,
      true
    );
  }
  trackUpdateNeeded(currentVersion, requiredVersion) {
    trackEvent(
      eventCategories.APP_UPDATE,
      `Update Modal Displayed, ` +
        `current: ${currentVersion}, needed: ${requiredVersion}`,
      true
    );
  }
  trackUpdateDiscarded() {
    trackEvent(eventCategories.APP_UPDATE, "Discarded Update Modal", false);
  }
  trackUpdateClicked() {
    trackEvent(eventCategories.APP_UPDATE, "Update Now Clicked", false);
  }
  trackInitialAppLoadTime(millis) {
    trackTiming(eventCategories.INITAL_APP_LOAD, "load", millis);
  }
  trackLogin(userId) {
    trackEvent(eventCategories.LOGIN, `User ${userId} logged in`, false);
  }
  trackLogout(userId) {
    trackEvent(eventCategories.LOGOUT, `User ${userId} logged out`, false);
  }
}

export default new Analytics();
