import { put, call, select } from "redux-saga/effects";
import notification from "../../components/notification";
import firebaseHelper from "../../helpers/firebase";
import superFetch from "../../helpers/superFetch";
import { generateBaseActionStrings } from "./actionUtils";
import { getApiName, getUpperFirst } from "./nameMapping";
import { debugLogging } from "../../helpers/logging";

// 1 hour
const DATA_STALE_TIME = 60 * 60 * 1000;

function isDataStale(lastFetchedTime) {
  if (!lastFetchedTime) return true;
  return Date.now() - lastFetchedTime > DATA_STALE_TIME;
}

async function getData(apiCall, token, oldData, lastFetchedTime) {
  if (!isDataStale(lastFetchedTime)) {
    return { data: oldData, fetchedTime: lastFetchedTime };
  }
  debugLogging("Fetching new data", apiCall);
  const newData = await superFetch.get(apiCall, {
    token
  });
  return {
    data: newData,
    fetchedTime: Date.now()
  };
}

function* getStore(name) {
  const storeName = getUpperFirst(name);
  const allStores = yield select();
  return allStores[storeName];
}

function* baseLoadStore(name) {
  const { store, storeFetchedTime } = yield call(getStore, name);
  const { loadStoreSuccessAction, loadErrorAction } = generateBaseActionStrings(
    name
  );
  const apiName = getApiName(name);
  try {
    const token = yield call(firebaseHelper.getIdToken);
    const apiCall = token
      ? `/${apiName}/store`
      : `/${apiName}/unauthorized/store`;
    let { data, fetchedTime } = yield call(
      getData,
      apiCall,
      token,
      store,
      storeFetchedTime
    );
    debugLogging(`${name} Fetched@${fetchedTime}, Store: `, data);
    if (!token) {
      fetchedTime = null;
    }
    yield put({
      type: loadStoreSuccessAction,
      payload: { store: data, fetchedTime }
    });
  } catch (error) {
    notification("error", error.message);
    yield put({
      type: loadErrorAction
    });
  }
}

function* baseLoadItems(name) {
  const { items, itemsFetchedTime } = yield call(getStore, name);
  const apiName = getApiName(name);
  const { loadItemsSuccessAction, loadErrorAction } = generateBaseActionStrings(
    name
  );
  const apiCall = `/${apiName}`;
  try {
    const token = yield call(firebaseHelper.getIdToken);
    let { data, fetchedTime } = yield call(
      getData,
      apiCall,
      token,
      items,
      itemsFetchedTime
    );
    debugLogging(`${name} Fetched@${fetchedTime}, Items: `, data);
    if (!token) {
      fetchedTime = null;
    }
    yield put({
      type: loadItemsSuccessAction,
      payload: { items: data, fetchedTime }
    });
  } catch (error) {
    notification("error", error.message);
    yield put({
      type: loadErrorAction
    });
  }
}

function* baseAdd(name, payload) {
  const apiName = getApiName(name);
  const { addSuccessAction, loadItemsAction } = generateBaseActionStrings(name);
  let { currentItem } = payload.payload;
  try {
    const token = yield call(firebaseHelper.getIdToken);
    const apiCall = token
      ? `/${apiName}/item`
      : `/${apiName}/unauthorized/item`;
    const { itemId } = yield call(superFetch.post, apiCall, {
      body: { item: currentItem },
      token
    });
    currentItem.id = itemId;
    yield put({
      type: addSuccessAction,
      payload: { currentItem }
    });
  } catch (error) {
    notification("error", error.message);
    yield put({
      type: loadItemsAction
    });
  }
}

function* baseUpdate(name, payload) {
  const apiName = getApiName(name);
  const { updateSuccessAction, loadItemsAction } = generateBaseActionStrings(
    name
  );
  let { currentItem } = payload.payload;
  try {
    const token = yield call(firebaseHelper.getIdToken);
    yield call(superFetch.put, `/${apiName}/item/${currentItem.id}`, {
      body: { item: currentItem },
      token
    });
    yield put({
      type: updateSuccessAction,
      payload: { currentItem }
    });
  } catch (error) {
    notification("error", error.message);
    yield put({
      type: loadItemsAction
    });
  }
}

function* baseDelete(name, payload) {
  const apiName = getApiName(name);
  const { deleteSuccessAction, loadItemsAction } = generateBaseActionStrings(
    name
  );
  const { currentItem } = payload.payload;
  try {
    const token = yield call(firebaseHelper.getIdToken);
    yield call(superFetch.delete, `/${apiName}/item/${currentItem.id}`, {
      token
    });
    yield put({
      type: deleteSuccessAction,
      payload: { currentItem }
    });
  } catch (error) {
    notification("error", error.message);
    yield put({
      type: loadItemsAction
    });
  }
}

export {
  baseLoadStore,
  baseLoadItems,
  baseAdd,
  baseUpdate,
  baseDelete,
  getData,
  getStore
};
