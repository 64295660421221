import React from "react";
import { injectIntl, intlShape } from "react-intl";
import { DatePicker } from "antd";

const LocalizedDatePicker = props => {
  const { intl, placeholder } = props;
  const datePickerProps = { ...props };
  if (placeholder) {
    datePickerProps.placeholder = intl.formatMessage({ id: placeholder });
  }
  return <DatePicker {...datePickerProps} />;
};

LocalizedDatePicker.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(LocalizedDatePicker);
