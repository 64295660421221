import Enlang from "./entries/en-US";
import Zhlang from "./entries/zh-Hans-CN";
import ZhTWlang from "./entries/zh-TW";
import { addLocaleData } from "react-intl";

const AppLocale = {
  english: Enlang,
  simplifiedChinese: Zhlang,
  tranditionalChinese: ZhTWlang
};
addLocaleData(AppLocale.english.data);
addLocaleData(AppLocale.simplifiedChinese.data);
addLocaleData(AppLocale.tranditionalChinese.data);

export default AppLocale;
