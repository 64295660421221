import React, { Component } from "react";
import { Upload, Icon, Modal, message } from "antd";
import _ from "lodash";
import firebaseHelper from "../../helpers/firebase";
import uuid from "uuid";
import IntlMessages from "../utility/intlMessages";
import LocalizedMessage from "../i18n/localizedMessage";

const validImageTypes = ["image/jpeg", "image/png"];

function beforeUpload(file) {
  const isImage = _.includes(validImageTypes, file.type);
  if (!isImage) {
    message.error(<LocalizedMessage messageId="imageUploader.onlyImage" />);
  }
  const isLessThan10M = file.size / 1024 / 1024 < 10;
  if (!isLessThan10M) {
    message.error(<LocalizedMessage messageId="imageUploader.sizeLimit" />);
  }
  return isImage && isLessThan10M;
}

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: "",
      fileList: [],
      loading: false,
      initialized: false
    };
    this.onRemove = this.onRemove.bind(this);
    this.startUploading = this.startUploading.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (state.initialized) {
      return null;
    }
    const fileList = _.map(props.initialValue, url => {
      return {
        uid: uuid(),
        url: url,
        status: "done"
      };
    });
    return { fileList, initialized: true };
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    });
  };

  uploadFile(info) {
    this.startUploading(info.file);
  }

  startUploading = async file => {
    const { onChange } = this.props;
    const { fileList } = this.state;
    const newFile = {
      uid: file.uid,
      url: null,
      status: "uploading"
    };
    this.setState({ fileList: _.flatten([fileList, newFile]), loading: true });
    const url = await firebaseHelper.uploadFile(file);
    newFile.url = url;
    newFile.status = "done";
    const newList = _.flatten([fileList, newFile]);
    this.setState({ fileList: newList, loading: false });
    if (onChange) {
      onChange(_.map(newList, "url"));
    }
  };

  onRemove(fileToRemove) {
    const { onChange } = this.props;
    const { fileList } = this.state;
    const newList = _.filter(
      _.map(fileList, file => {
        return file.uid === fileToRemove.uid ? null : { ...file };
      })
    );
    this.setState({ fileList: newList });
    if (onChange) {
      onChange(_.map(newList, "url"));
    }
  }

  render() {
    const { previewVisible, previewImage, fileList, loading } = this.state;
    const { imageCount, disabled } = this.props;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">
          <IntlMessages id="formDisplay.upload" />
        </div>
      </div>
    );
    return (
      <div className="clearfix">
        <Upload
          disabled={disabled || loading}
          customRequest={this.uploadFile}
          listType="picture-card"
          beforeUpload={beforeUpload}
          fileList={fileList}
          onPreview={this.handlePreview}
          onRemove={this.onRemove}
        >
          {fileList.length >= (imageCount || 3) ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="pictures" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}
