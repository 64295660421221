import { apiUrl } from "../settings";
import analytics from "./analytics";
import firebaseHelper from "./firebase";

const customHeader = token => ({
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: "Bearer " + token || undefined
});

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function apiCall(url, method, token, body) {
  let request = {
    method,
    headers: customHeader(token)
  };
  if (method !== "get") {
    request.body = JSON.stringify(body);
  }
  return await fetch(`${apiUrl}${url}`, request);
}

async function fetchWithRetries(url, method, token, body, shouldRetry) {
  let totalTries = shouldRetry ? 8 : 1;
  let tokenToUse = token;
  const longestRetryWaitSeconds = 5;
  while (totalTries) {
    try {
      const response = await apiCall(url, method, tokenToUse, body);
      const res = await (response.status === 204
        ? Promise.resolve({})
        : response.json());
      if (res.code) {
        throw res;
      }
      return res;
    } catch (err) {
      totalTries--;
      if (totalTries === 0) {
        console.error("Out of retries!!");
        console.error("Fetch Error: ", err);
        analytics.trackAPICallErrors(method, url, body, err, false);
        throw err;
      }
      const retryWait = longestRetryWaitSeconds / totalTries;
      console.warn("Fetch Error", err);
      console.warn("Waiting ", retryWait, "seconds to try again.");
      await sleep(retryWait * 1000);
      console.warn("Retrying.. tries left: ", totalTries);
      analytics.trackAPICallErrors(method, url, body, err, true);
      if (tokenToUse) {
        tokenToUse = await firebaseHelper.getIdToken();
      }
    }
  }
}

const base = async (method, url, data = {}, shouldRetry = true) => {
  const { token } = data;
  return await fetchWithRetries(url, method, token, data.body, shouldRetry);
};

const SuperFetch = {};
["get", "post", "put", "delete"].forEach(method => {
  SuperFetch[method] = base.bind(null, method);
});
export default SuperFetch;
