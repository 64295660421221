import _ from "lodash";
import { all, takeEvery, call, put } from "redux-saga/effects";
import notification from "../../components/notification";
import firebaseHelper from "../../helpers/firebase";
import { debugLogging } from "../../helpers/logging";
import { generateBaseActionStrings } from "../common/actionUtils";
import {
  baseLoadItems,
  baseLoadStore,
  baseAdd,
  baseUpdate,
  baseDelete,
  getData,
  getStore
} from "../common/sagaUtils";
import { getApiName } from "../common/nameMapping";
import actions from "./actions";
import inviteeActions from "../invitee/actions";

const name = "invite";

const {
  loadItemsAction,
  loadStoreAction,
  addAction,
  updateAction,
  updateSuccessAction,
  deleteAction,
  loadErrorAction
} = generateBaseActionStrings(name);

const updateItem = _.partial(baseUpdate, name);

function* loadMyInvite() {
  const { myInvite, myInviteFetchedTime } = yield call(getStore, name);
  const apiName = getApiName(name);
  try {
    const token = yield call(firebaseHelper.getIdToken);
    const apiCall = `/${apiName}/me`;
    const { data, fetchedTime } = yield call(
      getData,
      apiCall,
      token,
      myInvite,
      myInviteFetchedTime
    );
    debugLogging(`${name} Fetched@${fetchedTime}, My-Invite: `, data);
    yield put({
      type: actions.LOAD_MY_INVITE_SUCCESS,
      payload: { myInvite: data, fetchedTime }
    });
  } catch (error) {
    notification("error", error.message);
    yield put({
      type: loadErrorAction
    });
  }
}

function* changeLanguage(payload) {
  const { myInvite, language } = payload.payload;
  yield call(updateItem, {
    payload: { currentItem: { ...myInvite, language } }
  });
  window.location.reload(true);
}

function* updateInviteSuccess(payload) {
  yield put({
    type: inviteeActions.LOAD_INVITEES
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(loadItemsAction, _.partial(baseLoadItems, name)),
    takeEvery(loadStoreAction, _.partial(baseLoadStore, name)),
    takeEvery(addAction, _.partial(baseAdd, name)),
    takeEvery(updateAction, updateItem),
    takeEvery(deleteAction, _.partial(baseDelete, name)),
    takeEvery(actions.LOAD_MY_INVITE, loadMyInvite),
    takeEvery(actions.CHANGE_LANGUAGE, changeLanguage),
    takeEvery(updateSuccessAction, updateInviteSuccess)
  ]);
}
