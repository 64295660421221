import Auth from "./auth/reducer";
import App from "./app/reducer";
import Invite from "./invite/reducer";
import Invitee from "./invitee/reducer";
import Feedback from "./feedback/reducer";
import Agenda from "./agenda/reducer";
import AppVisit from "./appVisit/reducer";
import Travel from "./travel/reducer";
import Faq from "./faq/reducer";
import Poi from "./poi/reducer";
import Photo from "./photo/reducer";
import Story from "./story/reducer";
import Registry from "./registry/reducer";
import RegistryContributingOptions from "./registryContributingOptions/reducer";
import StaticContent from "./staticContent/reducer";
import LanguageSwitcher from "./languageSwitcher/reducer";

export default {
  Auth,
  App,
  Invite,
  Invitee,
  Feedback,
  Agenda,
  AppVisit,
  Travel,
  Faq,
  Poi,
  Photo,
  Story,
  Registry,
  RegistryContributingOptions,
  StaticContent,
  LanguageSwitcher
};
