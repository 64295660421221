import React from "react";
import { injectIntl, intlShape } from "react-intl";
import { Input } from "antd";
const { TextArea } = Input;

const LocalizedTextArea = props => {
  const { intl, placeholder } = props;
  const textAreaProps = { ...props };
  if (placeholder) {
    textAreaProps.placeholder = intl.formatMessage({ id: placeholder });
  }
  return <TextArea {...textAreaProps} />;
};

LocalizedTextArea.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(LocalizedTextArea);
