import styled from "styled-components";

const Fieldset = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

const FieldTitle = styled.div`
  font-weight: 500;
  margin-top: 10px;
`;

export { Fieldset, FieldTitle };
