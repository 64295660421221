import styled from "styled-components";

const LanguageSelector = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  img {
    height: 30px;
  }
`;
export { LanguageSelector };
