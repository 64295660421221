import React, { Component } from "react";
import { connect } from "react-redux";
import feedbackActions from "../../redux/feedback/actions";
import EditItemModalForm from "../../components/editItemModalForm";
import IntlMessages from "../../components/utility/intlMessages";

const formTitle = {
  update: <IntlMessages id="feedback.updateFormTitle" />,
  add: <IntlMessages id="feedback.addFormTitle" />
};

const okButtonText = {
  update: <IntlMessages id="feedback.updateButton" />,
  add: <IntlMessages id="feedback.addButton" />
};

class EditFeedbackItemForm extends Component {
  successMessage = () => {
    const { hostsContacts } = this.props.feedback;
    return (
      <div>
        <h3>
          <IntlMessages id="feedback.submittedTitle" />
        </h3>
        <div>
          {hostsContacts ? (
            <div>
              <IntlMessages id="feedback.submittedContent" />
              <p>
                <a href={`mailto:${hostsContacts.email}`}>
                  {hostsContacts.email}
                </a>
              </p>
              <p>
                <IntlMessages id="feedback.or" />
              </p>
              <p>
                {hostsContacts.phone.wai} <IntlMessages id="feedback.waichip" />
              </p>
              <p>
                {hostsContacts.phone.shan} <IntlMessages id="feedback.shan" />
              </p>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  render() {
    const {
      toggleEditFeedbackItem,
      addFeedbackItem,
      updateFeedbackItem,
      loadFeedbackStore,
      feedback
    } = this.props;
    const { currentItem } = feedback;
    const title = currentItem ? formTitle.update : formTitle.add;
    const okButton = currentItem ? okButtonText.update : okButtonText.add;
    const opts = {
      state: feedback,
      addItem: addFeedbackItem,
      updateItem: updateFeedbackItem,
      toggleEditItem: toggleEditFeedbackItem,
      loadStore: loadFeedbackStore,
      successMessage: this.successMessage(),
      title,
      okButton
    };
    return <EditItemModalForm {...opts} />;
  }
}

export default connect(
  state => ({
    feedback: state.Feedback
  }),
  {
    ...feedbackActions
  }
)(EditFeedbackItemForm);
