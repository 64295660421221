import React, { Component } from "react";
import _ from "lodash";
import Modal from "../feedback/modal";
import Form from "../uielements/form";
import customComponents from "../custom/customComponents";
import { Fieldset } from "../custom/customComponents.style";
import dateUtils from "../../helpers/dateUtils";
import IntlMessages from "../utility/intlMessages";
import Button from "../uielements/button";

const {
  generateInput,
  generateSelect,
  generateEditor,
  generateDatePicker,
  generateImageUploader,
  generateTextArea
} = customComponents;

const { turnStringDateToMoment, turnMomentDateIntoString } = dateUtils;

function processItemForComponents(item) {
  if (!item) return item;
  const newItem = { ...item };
  if (newItem.time) {
    newItem.time = turnStringDateToMoment(newItem.time);
  }
  return { ...newItem };
}

function processItemFromComponents(item) {
  if (!item) return item;
  const newItem = { ...item };
  if (newItem.time) {
    newItem.time = turnMomentDateIntoString(newItem.time);
  }
  return { ...newItem };
}

class EditItemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editItemModalActive: false,
      details: null,
      picture: null,
      thumbnail: null,
      answer: null
    };
    props.loadStore();
  }

  static getDerivedStateFromProps(props, state) {
    if (state.editItemModalActive === props.state.editItemModalActive)
      return null;
    props.form.resetFields();
    return {
      editItemModalActive: props.state.editItemModalActive,
      details: null,
      picture: null,
      thumbnail: null,
      answer: null
    };
  }

  submitItem(submitFunc) {
    const { addItem } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      const { details, picture, answer, thumbnail } = this.state;
      let { currentItem } = this.props.state;
      const newItem = _.pickBy(
        processItemFromComponents({
          ...values,
          details,
          picture,
          thumbnail,
          answer
        })
      );
      if (!currentItem) currentItem = {};
      const updatedItem = {
        ...currentItem,
        ...newItem
      };
      const fieldsToOmit = ["isLoading"];
      if (submitFunc === addItem) {
        fieldsToOmit.push("id");
      }
      submitFunc(_.omit(updatedItem, fieldsToOmit));
    });
  }

  handleRecord = e => {
    e.preventDefault();
    const { currentItem } = this.props.state;
    const { updateItem, addItem } = this.props;
    const submitFunc = currentItem && currentItem.id ? updateItem : addItem;
    this.submitItem(submitFunc);
  };

  copyItem() {
    this.submitItem(this.props.addItem);
  }

  copyItemButton() {
    const { currentItem } = this.props.state;
    if (!currentItem || !currentItem.id) return null;
    return (
      <Button type="primary" onClick={this.copyItem.bind(this, null)}>
        <IntlMessages id="formLabel.copyItem" />
      </Button>
    );
  }

  onDetailsChange = value => {
    this.setState({ details: value });
  };

  onPictureChange = value => {
    this.setState({ picture: value });
  };

  onThumbnailChange = value => {
    this.setState({ thumbnail: value });
  };

  onAnswerChange = value => {
    this.setState({ answer: value });
  };

  render() {
    const { currentItem, store, storeLoading } = this.props.state;
    const {
      title,
      okButton,
      successMessage,
      inviteCodeValidation
    } = this.props;
    const { editItemModalActive } = this.state;
    const isLoading = currentItem && currentItem.isLoading;
    const success = currentItem && currentItem.success;
    if (storeLoading) {
      return null;
    }
    const opts = {
      form: this.props.form,
      fields: store.fields,
      obj: processItemForComponents(currentItem),
      isLoading
    };
    const fields = (
      <Fieldset>
        {generateInput("inviteCode", {
          ...opts,
          validationFunc: inviteCodeValidation
        })}
        {generateSelect("type", opts)}
        {generateTextArea("comment", opts)}
        {generateInput("firstName", opts)}
        {generateInput("lastName", opts)}
        {generateInput("email", opts)}
        {generateInput("phoneNumber", opts)}
        {generateTextArea("message", opts)}
        {generateInput("name", opts)}
        {generateInput("title", opts)}
        {generateInput("group", opts)}
        {generateImageUploader("thumbnail", {
          ...opts,
          onChange: this.onThumbnailChange,
          imageCount: 1
        })}
        {generateInput("thumbnailWidth", opts)}
        {generateInput("thumbnailHeight", opts)}
        {generateInput("caption", opts)}
        {generateInput("question", opts)}
        {generateInput("subtitle", opts)}
        {generateInput("contentId", opts)}
        {generateSelect("language", opts)}
        {generateSelect("category", opts)}
        {generateDatePicker("time", opts)}
        {generateInput("order", opts)}
        {generateImageUploader("picture", {
          ...opts,
          onChange: this.onPictureChange,
          imageCount: 1
        })}
        {generateInput("pictureOnClickUrl", opts)}
        {generateEditor("details", {
          ...opts,
          onChange: this.onDetailsChange
        })}
        {generateEditor("answer", {
          ...opts,
          onChange: this.onAnswerChange
        })}
      </Fieldset>
    );
    const copyButton = this.copyItemButton();
    return (
      <Modal
        width={1000}
        visible={editItemModalActive}
        onClose={this.props.toggleEditItem.bind(this, null)}
        title={title}
        okText={success ? <IntlMessages id="formDisplay.success" /> : okButton}
        onOk={this.handleRecord}
        confirmLoading={isLoading}
        okButtonProps={{ disabled: success }}
        maskClosable={false}
        onCancel={this.props.toggleEditItem.bind(this, null)}
        cancelText={
          success ? (
            <IntlMessages id="formDisplay.close" />
          ) : (
            <IntlMessages id="formDisplay.cancel" />
          )
        }
      >
        {success ? successMessage : fields}
        <Form />
        <Fieldset>{copyButton}</Fieldset>
      </Modal>
    );
  }
}

const WrappedEditItemForm = Form.create()(EditItemForm);
export default WrappedEditItemForm;
