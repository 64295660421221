import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import { firebaseConfig } from "../../settings";
import SuperFetch from "../superFetch";
import uuid from "uuid";
import analytics from "../analytics";

const valid =
  firebaseConfig && firebaseConfig.apiKey && firebaseConfig.projectId;

if (valid) {
  firebase.initializeApp(firebaseConfig);
}
const firebaseAuth = valid && firebase.auth;
const firebaseStorage = valid && firebase.storage;

class FirebaseHelper {
  isValid = valid;

  constructor() {
    this.logout = this.logout.bind(this);
  }

  login = async info => {
    const { rememberMe, inviteCode } = info;
    if (!this.isValid) {
      return;
    }
    const response = await SuperFetch.post(
      "/login",
      {
        body: { inviteCode }
      },
      false // no retry
    );
    const persistence = rememberMe
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;
    await firebaseAuth().setPersistence(persistence);
    return firebaseAuth().signInWithCustomToken(response.loginToken);
  };

  logout() {
    const currentUserId = this.getCurrentUserId();
    analytics.trackLogout(currentUserId);
    return firebaseAuth().signOut();
  }

  getIdToken = async () => {
    if (!firebaseAuth().currentUser) return null;
    const token = await firebaseAuth().currentUser.getIdToken();
    return token;
  };

  getCurrentUser() {
    return new Promise((resolve, reject) => {
      const unsubscribe = firebaseAuth().onAuthStateChanged(user => {
        unsubscribe();
        if (user && user.uid) {
          analytics.setUser(user.uid);
        }
        resolve(user);
      }, reject);
    });
  }

  getCurrentUserId() {
    return firebaseAuth().currentUser.uid;
  }

  uploadFile = async (file, path = "images") => {
    const currentUser = await this.getCurrentUser();
    const storageRef = firebaseStorage().ref();
    const randomStr = uuid();
    const fileRef = storageRef.child(
      `${currentUser.uid}/${path}/${randomStr}${file.name}`
    );
    const snapshot = await fileRef.put(file);
    return await snapshot.ref.getDownloadURL();
  };
}

export default new FirebaseHelper();
