import { baseReducer, baseInitState } from "../common/reducerUtils";

const initState = { ...baseInitState };
export default function reducer(state = initState, { type, payload }) {
  const newState = baseReducer(
    "registryContributingOptions",
    state,
    type,
    payload
  );
  return newState;
}
