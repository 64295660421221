import actions from "./actions";
import * as _ from "lodash";

const initState = {
  inviteesLoading: false,
  loadingInvitees: [],
  invitees: [],
  store: {},
  editInviteeModalActive: false,
  invitee: null
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.TOGGLE_EDIT_INVITEE:
      return {
        ...state,
        editInviteeModalActive: !state.editInviteeModalActive,
        invitee: payload.invitee
      };
    case actions.LOAD_INVITEES:
      return {
        ...state,
        inviteesLoading: true
      };
    case actions.LOAD_INVITEES_SUCCESS:
      return {
        ...state,
        inviteesLoading: false,
        loadingInvitees: [],
        invitee: { ...state.invitee, isLoading: false },
        invitees: payload.invitees,
        store: payload.store
      };
    case actions.ADD_INVITEE:
      return {
        ...state,
        invitee: { ...state.invitee, isLoading: true }
      };
    case actions.ADD_INVITEE_SUCCESS:
      return {
        ...state,
        inviteesLoading: false,
        invitees: _.flatten([state.invitees, payload.invitee]),
        editInviteeModalActive: false,
        invitee: null
      };
    case actions.UPDATE_INVITEE:
      return {
        ...state,
        invitee: { ...state.invitee, isLoading: true },
        loadingInvitees: _.flatten([state.loadingInvitees, payload.invitee.id])
      };
    case actions.UPDATE_INVITEE_SUCCESS:
      return {
        ...state,
        invitees: _.map(state.invitees, invitee => {
          return invitee.id === payload.invitee.id
            ? { ...invitee, ...payload.invitee }
            : { ...invitee };
        }),
        editInviteeModalActive: false,
        invitee: null,
        loadingInvitees: _.without(state.loadingInvitees, payload.invitee.id)
      };
    case actions.DELETE_INVITEE:
      return {
        ...state,
        invitee: { ...state.invite, isLoading: true },
        loadingInvitees: _.flatten([state.loadingInvitees, payload.invitee.id])
      };
    case actions.DELETE_INVITEE_SUCCESS:
      return {
        ...state,
        invitees: _.filter(
          _.map(state.invitees, invitee => {
            return invitee.id === payload.invitee.id ? null : { ...invitee };
          })
        ),
        invitee: null,
        loadingInvitees: _.without(state.loadingInvitees, payload.invitee.id)
      };
    case actions.LOAD_INVITEES_ERROR:
      return initState;

    default:
      return state;
  }
}
