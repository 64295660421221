import { generateBaseActions } from "../common/actionUtils";

const actions = {
  ...generateBaseActions("invite"),
  LOAD_MY_INVITE: "LOAD_MY_INVITE",
  LOAD_MY_INVITE_SUCCESS: "LOAD_MY_INVITE_SUCCESS",
  CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
  loadMyInvite: () => ({
    type: actions.LOAD_MY_INVITE
  }),
  changeLanguage: payload => ({
    type: actions.CHANGE_LANGUAGE,
    payload
  })
};

export default actions;
