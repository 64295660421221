import { isProd } from "../settings";

function debugLogging(...text) {
  if (isProd) {
    return;
  }
  console.debug(...text);
}

export { debugLogging };
