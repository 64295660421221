import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, LocaleProvider } from "antd";
import { IntlProvider } from "react-intl";
import { Debounce } from "react-throttle";
import _ from "lodash";
import WindowResizeListener from "react-window-size-listener";
import { ThemeProvider } from "styled-components";
import appActions from "../../redux/app/actions";
import inviteeActions from "../../redux/invitee/actions";
import analytics from "../../helpers/analytics";
import { initAllStores, storesLoading } from "../../redux/storeManager";
import appVisitActions from "../../redux/appVisit/actions";
import Spin from "../../components/feedback/spin.style";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";
import AppRouter from "./AppRouter";
import { themeConfig } from "../../settings";
import themes from "../../settings/themes";
import AppHolder from "./commonStyle";
import "./global.css";
import IntlMessages from "../../components/utility/intlMessages";
import { AppLocale } from "../../dashApp";
import { getCurrentLanguage } from "../LanguageSwitcher/config";
import WrappedEditFeedbackItemForm from "../feedback/editFeedbackItemForm";

const { Content, Footer } = Layout;
const { toggleAll, checkUpdate } = appActions;

const { addAppVisitItem } = appVisitActions;
const { loadInvitees } = inviteeActions;

let loadingStartTime = null;
let loadingFinishTime = null;

async function recordAppLoadTime() {
  if (loadingFinishTime) return;
  loadingFinishTime = new Date();
  const timeTook = loadingFinishTime - loadingStartTime;
  analytics.trackInitialAppLoadTime(timeTook);
}

export class App extends Component {
  componentDidMount() {
    loadingStartTime = new Date();
    this.props.loadInvitees();
    this.props.addAppVisitItem({});
    initAllStores();
    // check update every 30 minutes
    this.props.checkUpdate(30 * 60 * 1000);
  }

  isCurrentlyLoading() {
    const { invite, feedback, invitee, staticContent } = this.props;
    const currentlyLoading =
      _.some([
        invite.myInviteLoading,
        invitee.inviteesLoading,
        feedback.hostsContactsLoading,
        staticContent.itemsLoading
      ]) || storesLoading([feedback]);
    if (!currentlyLoading && loadingStartTime) {
      recordAppLoadTime();
    }
    return currentlyLoading;
  }

  render() {
    const { url } = this.props.match;
    const { height, language } = this.props;
    const currentAppLocale =
      AppLocale[getCurrentLanguage(language || "english").locale];
    const appHeight = window.innerHeight;
    const currentlyLoading = this.isCurrentlyLoading();

    return (
      <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ThemeProvider theme={themes[themeConfig.theme]}>
            <AppHolder>
              <Layout style={{ height: appHeight }}>
                <Debounce time="1000" handler="onResize">
                  <WindowResizeListener
                    onResize={windowSize =>
                      this.props.toggleAll(
                        windowSize.windowWidth,
                        windowSize.windowHeight
                      )
                    }
                  />
                </Debounce>
                <Spin spinning={currentlyLoading} size="large">
                  <Topbar url={url} locale={currentAppLocale.locale} />
                  <Layout style={{ flexDirection: "row", overflowX: "hidden" }}>
                    <Sidebar url={url} />
                    <Layout
                      className="isoContentMainLayout"
                      style={{
                        height: height
                      }}
                    >
                      <Content
                        className="isomorphicContent"
                        style={{
                          padding: "70px 0 0",
                          flexShrink: "0",
                          background: "#f1f3f6",
                          position: "relative"
                        }}
                      >
                        <AppRouter url={url} />
                      </Content>

                      <Footer
                        style={{
                          background: "#ffffff",
                          textAlign: "center",
                          borderTop: "1px solid #ededed"
                        }}
                      >
                        <IntlMessages id="bottomBar.copyRightMessage" />
                      </Footer>
                    </Layout>
                  </Layout>
                </Spin>
                <WrappedEditFeedbackItemForm />
              </Layout>
            </AppHolder>
          </ThemeProvider>
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

export default connect(
  state => ({
    height: state.App.height,
    invitee: state.Invitee,
    invite: state.Invite,
    feedback: state.Feedback,
    staticContent: state.StaticContent,
    language: state.Invite.myInvite.language
  }),
  {
    toggleAll,
    checkUpdate,
    loadInvitees,
    addAppVisitItem
  }
)(App);
