import { getUpper, getUpperFirst } from "./nameMapping";

function generateBaseActionStrings(name) {
  const upper = getUpper(name);
  return {
    toggleAction: `TOGGLE_EDIT_${upper}_ITEM`,
    loadItemsAction: `LOAD_${upper}_ITEMS`,
    loadItemsSuccessAction: `LOAD_${upper}_ITEMS_SUCCESS`,
    loadStoreAction: `LOAD_${upper}_STORE`,
    loadStoreSuccessAction: `LOAD_${upper}_STORE_SUCCESS`,
    addAction: `ADD_${upper}_ITEM`,
    addSuccessAction: `ADD_${upper}_ITEM_SUCCESS`,
    updateAction: `UPDATE_${upper}_ITEM`,
    updateSuccessAction: `UPDATE_${upper}_ITEM_SUCCESS`,
    deleteAction: `DELETE_${upper}_ITEM`,
    deleteSuccessAction: `DELETE_${upper}_ITEM_SUCCESS`,
    loadErrorAction: `LOAD_${upper}_ERROR`
  };
}

function generateBaseActions(name) {
  const upperFirst = getUpperFirst(name);

  const {
    toggleAction,
    loadItemsAction,
    loadItemsSuccessAction,
    loadStoreAction,
    loadStoreSuccessAction,
    addAction,
    addSuccessAction,
    updateAction,
    updateSuccessAction,
    deleteAction,
    deleteSuccessAction,
    loadErrorAction
  } = generateBaseActionStrings(name);

  const actions = {};
  actions[toggleAction] = toggleAction;
  actions[loadItemsAction] = loadItemsAction;
  actions[loadStoreAction] = loadStoreAction;
  actions[addAction] = addAction;
  actions[updateAction] = updateAction;
  actions[deleteAction] = deleteAction;
  actions[loadItemsSuccessAction] = loadItemsSuccessAction;
  actions[loadStoreSuccessAction] = loadStoreSuccessAction;
  actions[addSuccessAction] = addSuccessAction;
  actions[updateSuccessAction] = updateSuccessAction;
  actions[deleteSuccessAction] = deleteSuccessAction;
  actions[loadErrorAction] = loadErrorAction;

  actions[`toggleEdit${upperFirst}Item`] = (currentItem = null) => ({
    type: toggleAction,
    payload: { currentItem }
  });
  actions[`load${upperFirst}Items`] = () => ({
    type: loadItemsAction
  });
  actions[`load${upperFirst}Store`] = () => ({
    type: loadStoreAction
  });
  actions[`add${upperFirst}Item`] = currentItem => ({
    type: addAction,
    payload: { currentItem }
  });
  actions[`update${upperFirst}Item`] = currentItem => ({
    type: updateAction,
    payload: { currentItem }
  });
  actions[`delete${upperFirst}Item`] = currentItem => ({
    type: deleteAction,
    payload: { currentItem }
  });
  return actions;
}

export { generateBaseActions, generateBaseActionStrings };
