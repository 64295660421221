import React from "react";
import { Select, Radio, notification, Icon, Button } from "antd";
import * as _ from "lodash";
import fieldValidator from "../../helpers/fieldValidator";
import dateUtils from "../../helpers/dateUtils";
import analytics from "../../helpers/analytics";
import Form from "../../components/uielements/form";
import Popconfirms from "../../components/feedback/popconfirm";
import { FieldTitle } from "./customComponents.style";
import ImageUploader from "./imageUploader";
import { Details, DetailsInner } from "./commonStyle";
import IntlMessages from "../../components/utility/intlMessages";
import momentUtils from "../../helpers/momentUtils";
import LocalizedInput from "../../components/i18n/localizedInput";
import LocalizedTextArea from "../../components/i18n/localizedTextArea";
import LocalizedEditor from "../../components/i18n/localizedEditor";
import LocalizedDatePicker from "../../components/i18n/localizedDatePicker";
import LocalizedMessage from "../../components/i18n/localizedMessage";

const moment = momentUtils.getMoment();

const FormItem = Form.Item;
const Option = Select.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { isValueValid, OPTIONS } = fieldValidator;

const {
  DEFAULT_LOCALIZED_FORMAT,
  DEFAULT_DATE_FORMAT,
  WEDDING_DATE,
  localizeMomentDate,
  getWeddingDateInMoment
} = dateUtils;

const optionText = {
  language: {
    "en-US": <IntlMessages id="formLangugage.english" />,
    "zh-TW": <IntlMessages id="formLangugage.traditional" />,
    "zh-CN": <IntlMessages id="formLangugage.simplified" />
  },
  type: {
    friends: <IntlMessages id="formInviteType.friends" />,
    hosts: <IntlMessages id="formInviteType.hosts" />,
    "shan-relatives": <IntlMessages id="formInviteType.shanRelatives" />,
    "wai-relatives": <IntlMessages id="formInviteType.waiRelatives" />
  }
};

const formLabel = {
  inviteCode: <IntlMessages id="formLabel.inviteCode" />,
  language: <IntlMessages id="formLabel.language" />,
  type: <IntlMessages id="formLabel.type" />,
  comment: <IntlMessages id="formLabel.comment" />,
  relationship: <IntlMessages id="formLabel.relationship" />,
  firstName: <IntlMessages id="formLabel.firstName" />,
  lastName: <IntlMessages id="formLabel.lastName" />,
  email: <IntlMessages id="formLabel.email" />,
  phoneNumber: <IntlMessages id="formLabel.phoneNumber" />,
  attending: <IntlMessages id="formLabel.attending" />,
  dietaryRestrictions: <IntlMessages id="formLabel.dietaryRestrictions" />,
  stayingAt: <IntlMessages id="formLabel.stayingAt" />,
  additionalComments: <IntlMessages id="formLabel.additionalComments" />,
  arrivingDate: <IntlMessages id="formLabel.arrivingDate" />,
  departingDate: <IntlMessages id="formLabel.departingDate" />,
  message: <IntlMessages id="formLabel.message" />,
  createdTime: <IntlMessages id="formLabel.createdTime" />,
  ip: <IntlMessages id="formLabel.ip" />,
  inviteId: <IntlMessages id="formLabel.inviteId" />,
  invitee: <IntlMessages id="formLabel.invitee" />,
  invite: <IntlMessages id="formLabel.invite" />,
  feedback: <IntlMessages id="formLabel.feedback" />,
  title: <IntlMessages id="formLabel.title" />,
  group: <IntlMessages id="formLabel.group" />,
  thumbnail: <IntlMessages id="formLabel.thumbnail" />,
  thumbnailWidth: <IntlMessages id="formLabel.thumbnailWidth" />,
  thumbnailHeight: <IntlMessages id="formLabel.thumbnailHeight" />,
  caption: <IntlMessages id="formLabel.caption" />,
  subtitle: <IntlMessages id="formLabel.subtitle" />,
  time: <IntlMessages id="formLabel.time" />,
  picture: <IntlMessages id="formLabel.picture" />,
  details: <IntlMessages id="formLabel.details" />,
  agendaItem: <IntlMessages id="formLabel.agendaItem" />,
  travelItem: <IntlMessages id="formLabel.travelItem" />,
  order: <IntlMessages id="formLabel.order" />,
  question: <IntlMessages id="formLabel.question" />,
  answer: <IntlMessages id="formLabel.answer" />,
  registryItem: <IntlMessages id="formLabel.registryItem" />,
  name: <IntlMessages id="formLabel.name" />,
  pictureOnClickUrl: <IntlMessages id="formLabel.pictureOnClickUrl" />,
  faqItem: <IntlMessages id="formLabel.faqItem" />,
  poiItem: <IntlMessages id="formLabel.poiItem" />,
  registryContributingOptionsItem: (
    <IntlMessages id="formLabel.registryContributingOptionsItem" />
  ),
  storyItem: <IntlMessages id="formLabel.storyItem" />,
  feedbackItem: <IntlMessages id="formLabel.feedbackItem" />,
  volunteering: <IntlMessages id="formLabel.volunteering" />,
  rsvpStep: <IntlMessages id="formLabel.rsvpStep" />,
  category: <IntlMessages id="formLabel.category" />,
  contentId: <IntlMessages id="formLabel.contentId" />,
  staticContentItem: <IntlMessages id="formLabel.staticContentItem" />,
  timeVisited: <IntlMessages id="formLabel.timeVisited" />
};

const placeHolderText = {
  inviteCode: "placeHolder.inviteCode",
  comment: "placeHolder.comment",
  stayingAt: "placeHolder.stayingAt",
  additionalComments: "placeHolder.additionalComments",
  arrivingDate: "placeHolder.arrivingDate",
  departingDate: "placeHolder.departingDate",
  details: "placeHolder.details"
};

function updateNotification(notificationDuration) {
  const updateButton = (
    <Button
      type="primary"
      size="small"
      onClick={() => {
        analytics.trackUpdateClicked();
        document.location.reload(true);
      }}
    >
      <LocalizedMessage messageId="updateModal.updateNow" />
    </Button>
  );
  notification.open({
    message: <LocalizedMessage messageId="updateModal.updateNeeded" />,
    description: (
      <LocalizedMessage messageId="updateModal.updateNeededDetail" />
    ),
    btn: updateButton,
    duration: notificationDuration,
    onClose: () => {
      analytics.trackUpdateDiscarded();
    },
    icon: <Icon type="exclamation-circle" style={{ color: "red" }} />
  });
}

function generateConfirmDeleteText(key) {
  return (
    <IntlMessages
      id="formDisplay.deleteConfirm"
      values={{ itemName: formLabel[key] }}
    />
  );
}

function generateRequiredText(key) {
  return (
    <IntlMessages
      id="formDisplay.requiredText"
      values={{ itemName: formLabel[key] }}
    />
  );
}

function generateInvalidText(key) {
  return (
    <IntlMessages
      id="formDisplay.invalidText"
      values={{ itemName: formLabel[key] }}
    />
  );
}

function getValueToDisplay(key, obj) {
  if (!obj) return <IntlMessages id="formDisplay.notProvided" />;
  if (_.has(optionText, key)) {
    return optionText[key][obj[key]];
  }
  return obj[key] || <IntlMessages id="formDisplay.notProvided" />;
}

function displayKeyValue(key, opts) {
  const { store, obj, displayFieldsNotInStore } = opts;
  opts = { fields: store.fields, obj };
  if (!(shouldDisplay(key, opts) || displayFieldsNotInStore)) {
    return null;
  }
  const title = formLabel[key];
  const content = getValueToDisplay(key, obj);
  return (
    <div>
      <FieldTitle>
        {title}
        {": "}
      </FieldTitle>
      <p>{content}</p>
    </div>
  );
}

function displayValue(key, opts) {
  const { store, obj } = opts;
  opts = { fields: store.fields, obj };
  if (!shouldDisplay(key, opts)) {
    return null;
  }
  return getValueToDisplay(key, obj);
}

function displayTime(key, opts) {
  const { store, obj } = opts;
  opts = { fields: store.fields, obj };
  if (!shouldDisplay(key, opts)) {
    return null;
  }
  let content = <IntlMessages id="formDisplay.notProvided" />;
  if (obj && obj[key]) {
    content = localizeMomentDate(moment(obj[key]));
  }
  const title = formLabel[key];
  return (
    <div>
      <FieldTitle>
        {title}
        {": "}
      </FieldTitle>
      <p>{content}</p>
    </div>
  );
}

function displayKeyValues(keys, opts) {
  const { obj } = opts;
  return _.map(keys, key => {
    const displayFunc = _.includes(["arrivingDate", "departingDate"], key)
      ? displayTime
      : displayKeyValue;
    return <div key={`${obj.id}:${key}`}>{displayFunc(key, opts)}</div>;
  });
}

function displayHTMLMessage(message) {
  if (!message) return null;
  return (
    <Details>
      <DetailsInner
        className="ql-editor"
        dangerouslySetInnerHTML={{
          __html: message
        }}
      />
    </Details>
  );
}

function getObjId(opts) {
  const { obj } = opts;
  if (obj && obj.id) return obj.id;
  return "default_id";
}

function getInitialValue(key, opts) {
  const { obj, fields, disableSettingDefault } = opts;
  if (obj) {
    return obj[key];
  }
  if (disableSettingDefault) {
    return null;
  }
  const options = _.find(fields[key].validationRules, { key: OPTIONS });
  if (options) {
    return options.value[0];
  }
  return null;
}

function shouldDisplay(key, opts) {
  const { fields } = opts;
  return _.has(fields, key);
}

function validateField(key, opts, rule, value, callback) {
  if (!value) {
    callback();
  }
  const { fields, validationFunc } = opts;
  const { validationRules } = fields[key];
  if (validationRules && !isValueValid(value, validationRules).isValid) {
    callback(generateInvalidText(key));
    return;
  }
  if (validationFunc && !validationFunc(value)) {
    callback(generateInvalidText(key));
    return;
  }
  callback();
}

function datePickerDateRenderer(current, specialDates = [WEDDING_DATE]) {
  const style = {};
  const currentDateString = current.format(DEFAULT_DATE_FORMAT);
  if (_.includes(specialDates, currentDateString)) {
    style.border = "3px solid #1890ff";
    style.borderRadius = "50%";
  }
  return (
    <div className="ant-calendar-date" style={style}>
      {current.date()}
    </div>
  );
}

function decorateField(key, field, opts) {
  if (!opts.form) {
    return field;
  }
  const { form, fields } = opts;
  const { getFieldDecorator } = form;
  const required = fields[key].required;
  const valdiate = _.partial(validateField, key, opts);
  const initalValue = getInitialValue(key, opts);

  return getFieldDecorator(key, {
    rules: [
      {
        required: required,
        message: generateRequiredText(key)
      },
      {
        validator: valdiate
      }
    ],
    initialValue: initalValue
  })(field);
}

function createInputField(key, opts) {
  const { isLoading, forceDisabled, fields } = opts;
  const disabled = forceDisabled || !fields[key].canUpdate || isLoading;
  const placeholder = placeHolderText[key];
  return <LocalizedInput disabled={disabled} placeholder={placeholder} />;
}

function createSelectField(key, opts) {
  const { isLoading, forceDisabled, fields } = opts;
  const disabled = forceDisabled || !fields[key].canUpdate || isLoading;
  const options =
    opts.options || _.find(fields[key].validationRules, { key: OPTIONS }).value;

  const style = { width: "100%", ...opts.style };
  return (
    <Select disabled={disabled} style={style}>
      {_.map(options, option => {
        const optionDisplayValue = _.has(optionText, key)
          ? optionText[key][option]
          : option;
        return (
          <Option key={`${key}:${option}`} value={option}>
            {optionDisplayValue}
          </Option>
        );
      })}
    </Select>
  );
}

function createTextAreaField(key, opts) {
  const { isLoading, forceDisabled, fields, rows } = opts;
  const disabled = forceDisabled || !fields[key].canUpdate || isLoading;
  const placeHolder = placeHolderText[key];
  return (
    <LocalizedTextArea
      disabled={disabled}
      placeholder={placeHolder}
      rows={rows || 5}
    />
  );
}

function createEditor(key, opts) {
  const { isLoading, forceDisabled, fields } = opts;
  const placeHolder = placeHolderText[key];
  const disabled = forceDisabled || !fields[key].canUpdate || isLoading;
  const initalValue = getInitialValue(key, opts);
  opts.initialValue = initalValue;
  opts.placeHolder = placeHolder;
  opts.disabled = disabled;
  opts.key = getObjId(opts);
  return <LocalizedEditor {...opts} />;
}

function createImageUploader(key, opts) {
  const { isLoading, forceDisabled, fields } = opts;
  const disabled = forceDisabled || !fields[key].canUpdate || isLoading;
  const initialValue = getInitialValue(key, opts);
  return (
    <ImageUploader
      key={getObjId(opts)}
      disabled={disabled}
      initialValue={initialValue}
      {...opts}
    />
  );
}

function createYesOrNoRadioGroup(key, opts) {
  const { isLoading, forceDisabled, fields, onChange } = opts;
  const disabled = forceDisabled || !fields[key].canUpdate || isLoading;
  return (
    <RadioGroup disabled={disabled} buttonStyle="solid" onChange={onChange}>
      <RadioButton value="yes">
        <IntlMessages id="formDisplay.yes" />
      </RadioButton>
      <RadioButton value="no">
        <IntlMessages id="formDisplay.no" />
      </RadioButton>
    </RadioGroup>
  );
}

function createDatePicker(key, opts) {
  const {
    isLoading,
    forceDisabled,
    fields,
    onChange,
    onOpenChange,
    disabledDate,
    dateRender,
    defaultPickerValue,
    dateFormat,
    open,
    hideTime
  } = opts;
  const disabled = forceDisabled || !fields[key].canUpdate || isLoading;
  const placeHolder = placeHolderText[key];
  const style = { width: "100%", ...opts.style };
  const openAttribute = open === undefined ? {} : { open: open };
  const datePickerOpts = {
    disabled,
    disabledDate,
    onChange,
    onOpenChange,
    style,
    ...openAttribute,
    placeholder: placeHolder,
    format: dateFormat || DEFAULT_LOCALIZED_FORMAT,
    dateRender: dateRender || datePickerDateRenderer,
    defaultPickerValue: defaultPickerValue || getWeddingDateInMoment(),
    showTime: !hideTime
  };
  return <LocalizedDatePicker {...datePickerOpts} />;
}

function generateInput(key, opts) {
  const { hideFeedback } = opts;
  if (!shouldDisplay(key, opts)) return null;
  const label = formLabel[key];
  const field = createInputField(key, opts);
  return (
    <FormItem label={label} hasFeedback={!hideFeedback}>
      {decorateField(key, field, opts)}
    </FormItem>
  );
}

function generateSelect(key, opts) {
  const { hideFeedback } = opts;
  if (!shouldDisplay(key, opts)) return null;
  const label = formLabel[key];
  const field = createSelectField(key, opts);
  return (
    <FormItem label={label} hasFeedback={!hideFeedback}>
      {decorateField(key, field, opts)}
    </FormItem>
  );
}

function generateTextArea(key, opts) {
  const { hideFeedback } = opts;
  if (!shouldDisplay(key, opts)) return null;
  const label = formLabel[key];
  const field = createTextAreaField(key, opts);
  return (
    <FormItem label={label} hasFeedback={!hideFeedback}>
      {decorateField(key, field, opts)}
    </FormItem>
  );
}

function generateYesOrNo(key, opts) {
  const { hideFeedback } = opts;
  if (!shouldDisplay(key, opts)) return null;
  const label = formLabel[key];
  const field = createYesOrNoRadioGroup(key, opts);
  return (
    <FormItem label={label} hasFeedback={!hideFeedback}>
      {decorateField(key, field, opts)}
    </FormItem>
  );
}

function generateDatePicker(key, opts) {
  const { hideFeedback } = opts;
  if (!shouldDisplay(key, opts)) return null;
  const label = formLabel[key];
  const field = createDatePicker(key, opts);
  return (
    <FormItem label={label} hasFeedback={!hideFeedback}>
      {decorateField(key, field, opts)}
    </FormItem>
  );
}

function generatePopconfirmDeocrator(okFunc, opts) {
  const { title, okText, cancelText, placement } = opts;
  return field => {
    return (
      <Popconfirms
        title={title || <IntlMessages id="formDisplay.areYouSure" />}
        okText={okText || <IntlMessages id="formDisplay.yes" />}
        cancelText={cancelText || <IntlMessages id="formDisplay.no" />}
        placement={placement || "topRight"}
        onConfirm={okFunc}
      >
        {field}
      </Popconfirms>
    );
  };
}

function generateEditor(key, opts) {
  const { hideFeedback } = opts;
  if (!shouldDisplay(key, opts)) return null;
  const label = formLabel[key];
  const field = createEditor(key, opts);
  return (
    <FormItem label={label} hasFeedback={!hideFeedback}>
      {field}
    </FormItem>
  );
}

function generateImageUploader(key, opts) {
  const { hideFeedback } = opts;
  if (!shouldDisplay(key, opts)) return null;
  const label = formLabel[key];
  const field = createImageUploader(key, opts);
  return (
    <FormItem label={label} hasFeedback={!hideFeedback}>
      {field}
    </FormItem>
  );
}

export default {
  updateNotification,
  generateConfirmDeleteText,
  generateInput,
  generateSelect,
  displayKeyValue,
  displayTime,
  displayKeyValues,
  displayValue,
  displayHTMLMessage,
  generateTextArea,
  generateYesOrNo,
  generateDatePicker,
  generatePopconfirmDeocrator,
  generateEditor,
  generateImageUploader,
  datePickerDateRenderer
};
